import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { ValoradorService } from "../servicios/valorador.service";
import { MatStepper } from "@angular/material";
import { MatSnackBar } from "@angular/material/snack-bar";
import { WebApiService } from "src/app/servicios/web-api.service";
import { PageEvent } from "@angular/material/paginator";

import { AppComponent } from "src/app/app.component";
import swal from "sweetalert2";

import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

import { GlobalService } from "../servicios/global.service";

import { BreakpointObserver } from "@angular/cdk/layout";
import { MatSidenav } from "@angular/material/sidenav";
import { delay } from "rxjs/operators";
declare var EXIF: any;

export interface marca {
  nombre: string;
}

@Component({
  selector: "app-guia-precio",
  templateUrl: "./guia-precio.component.html",
  styleUrls: ["./guia-precio.component.css"],
})
export class GuiaPrecioComponent implements OnInit {
  public urlFiltros = "http://api.libroazul.com.co/vehiculos/";
  public urlPrecios = "http://api.libroazul.com.co/guiaPrecios/precios";
  public loading = false;
  public listClases: any = [];
  public listMarcas: any = [];
  public listFamilias: any = [];
  public listCajaTipo: any = [];
  public listLineas: any = [];
  public listTraccion: any = [];
  public listForma: any = [];
  public listTipoMotor: any = [];
  public listTipoServicio: any = [];
  public listSegmento: any = [];
  public listEquipamento: any = [];
  public tipo_carroceria: any = [];

  public listPrecio: any = [];

  public familiasSeleccion: any = [];

  public listPrecios: any = [];

  public caja = 0;
  public eliminado = 0;

  public position = 0;
  public encontrado = false;

  public listAnoNuevo: any = [];
  public listAnoUsados: any = [];
  public cantAnosUsuados: 0;

  public basica: Boolean = false;
  public avanzada: Boolean = false;
  public profesional: Boolean = false;

  public marca;
  public familia;

  public linea;

  public formaVehiculo = 0;
  public traccion = 0;
  public segmento = 0;

  public servicio = 0;

  public motor = 0;

  public equipamiento = 0;
  public carroceria = 0;

  page_size: number = 50; // CANTIDAD DE ELEMENTOS POR PAGINA
  page_number: number = 1;
  pageSizeOptions = [5, 10, 20, 50, 100];
  constructor(
    private WebApiService: WebApiService,
    private _valoradorService: ValoradorService,
    private _globalService: GlobalService,
    private observer: BreakpointObserver
  ) {}

  ngOnInit() {
    this.getClase();
    this.getMarca();
    this.obtenerPrecio();
  }
  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }

  getClase() {
    this.loading = true;
    this._globalService.getData(this.urlFiltros + "clases").subscribe(
      (res) => {
        this.listClases = res;
      },
      (err) => {
        console.log(err);
      }
    );

    this._globalService.getData(this.urlFiltros + "caja").subscribe(
      (res) => {
        this.listCajaTipo = res;
      },
      (err) => {
        console.log(err);
      }
    );

    this._globalService.getData(this.urlFiltros + "traccion").subscribe(
      (res) => {
        this.listTraccion = res;
      },
      (err) => {
        console.log(err);
      }
    );

    this._globalService.getData(this.urlFiltros + "formas").subscribe(
      (res) => {
        this.listForma = res;
      },
      (err) => {
        console.log(err);
      }
    );

    this._globalService.getData(this.urlFiltros + "segmento").subscribe(
      (res) => {
        this.listSegmento = res;
      },
      (err) => {
        console.log(err);
      }
    );
    this._globalService.getData(this.urlFiltros + "tipomotor").subscribe(
      (res) => {
        this.listTipoMotor = res;
      },
      (err) => {
        console.log(err);
      }
    );

    this._globalService.getData(this.urlFiltros + "tiposervicio").subscribe(
      (res) => {
        this.listTipoServicio = res;
      },
      (err) => {
        console.log(err);
      }
    );

    this._globalService.getData(this.urlFiltros + "equipamiento").subscribe(
      (res) => {
        this.listEquipamento = res;
      },
      (err) => {
        console.log(err);
      }
    );

    this._globalService.getData(this.urlFiltros + "tipocarroceria").subscribe(
      (res) => {
        this.tipo_carroceria = res;
      },
      (err) => {
        console.log(err);
      }
    );
    this.loading = false;
  }

  getMarca() {
    this._globalService
      .getData(this.urlFiltros + "marcas?clase=" + 1)
      .subscribe(
        (res) => {
          this.listMarcas = res;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  tipoBusqueda(event) {
    let data = parseInt(event.value);
    switch (data) {
      case 1:
        this.basica = true;
        this.avanzada = false;
        this.profesional = false;
        this.listPrecios = [];
        this.listLineas = [];
        this.listFamilias = [];
        this.familiasSeleccion = [];
        this.caja = 0;
        this.formaVehiculo = 0;

        break;

      case 2:
        this.avanzada = true;
        this.profesional = false;
        this.listPrecios = [];
        this.listLineas = [];
        this.listFamilias = [];
        this.familiasSeleccion = [];
        this.caja = 0;
        this.formaVehiculo = 0;

        break;
      case 3:
        this.avanzada = false;
        this.profesional = true;
        this.listPrecios = [];
        this.listLineas = [];
        this.listFamilias = [];
        this.familiasSeleccion = [];
        this.caja = 0;
        this.formaVehiculo = 0;

        break;
    }
  }

  getDataFilter(key, event, dta) {
    let data = event.source.value;
    console.log(key);
    switch (key) {
      // Al seleccionar una clase se cargan las marcas
      case "marca":
        this._globalService
          .getData(this.urlFiltros + "marcas?clase=" + data)
          .subscribe(
            (res) => {
              this.listMarcas = [];
              this.listMarcas = res;
            },
            (err) => {
              console.log(err);
            }
          );

        break;
      // al seleccionar una marca se cargue la lista de familia
      case "familia":
        if (!event.checked) {
          let familia = this.EliminarData(dta.id, this.listFamilias);

          if (familia) {
            // let linea = this.EliminarData(data, this.listLineas);

            let pList = this.EliminarData(parseInt(data), this.listPrecios);
          }
        } else {
          let url;
          if (
            this.formaVehiculo === 0 &&
            this.segmento === 0 &&
            this.servicio === 0
          ) {
            url = this.urlFiltros + "familias?marca=" + data;
          } else if (
            this.formaVehiculo != 0 &&
            this.segmento === 0 &&
            this.servicio === 0
          ) {
            url =
              this.urlFiltros +
              "familias?marca=" +
              data +
              "&forma=" +
              this.formaVehiculo;
          } else if (
            this.formaVehiculo === 0 &&
            this.segmento != 0 &&
            this.servicio === 0
          ) {
            url =
              this.urlFiltros +
              "familias?marca=" +
              data +
              "&segmento=" +
              this.segmento;
          } else if (
            this.formaVehiculo === 0 &&
            this.segmento === 0 &&
            this.servicio != 0
          ) {
            url =
              this.urlFiltros +
              "familias?marca=" +
              data +
              "&tipo_servicio=" +
              this.servicio;
          } else if (
            this.formaVehiculo != 0 &&
            this.segmento != 0 &&
            this.servicio != 0
          ) {
            url =
              this.urlFiltros +
              "familias?marca=" +
              data +
              "&forma=" +
              this.formaVehiculo +
              "&segmento=" +
              this.segmento +
              "&tipo_servicio=" +
              this.servicio;
          }

          //cuando se selecciona la forma y un segmento
          else if (
            this.formaVehiculo != 0 &&
            this.segmento != 0 &&
            this.servicio === 0
          ) {
            url =
              this.urlFiltros +
              "familias?marca=" +
              data +
              "&forma=" +
              this.formaVehiculo +
              "&segmento=" +
              this.segmento;
          }
          //cuando se selecciona la forma y tipo servicio
          else if (
            this.formaVehiculo != 0 &&
            this.segmento === 0 &&
            this.servicio != 0
          ) {
            url =
              this.urlFiltros +
              "familias?marca=" +
              data +
              "&forma=" +
              this.formaVehiculo +
              "&tipo_servicio=" +
              this.servicio;
          }

          //segmento y tipo de servicio
          else if (
            this.formaVehiculo === 0 &&
            this.segmento != 0 &&
            this.servicio != 0
          ) {
            url =
              this.urlFiltros +
              "familias?marca=" +
              data +
              "&segmento=" +
              this.segmento +
              "&tipo_servicio=" +
              this.servicio;
          }

          this._globalService.getData(url).subscribe(
            (res) => {
              let array = {
                id: dta.id,
                nombre: dta.nombre,
                data: res,
              };

              this.listFamilias.push(array);

              if (res.length >= 1) {
                //para la lista de precios
                this._globalService
                  .getData(this.urlPrecios + "?marca=" + data)
                  .subscribe(
                    (res) => {
                      //Eliminamos los datos de inicio
                      for (
                        let index = 0;
                        index < this.listPrecios.length;
                        index++
                      ) {
                        const element = this.listPrecios[index];

                        if (element.id === 199703) {
                          this.listPrecios = [];
                        }
                      }

                      for (
                        let index = 0;
                        index < this.listPrecios.length;
                        index++
                      ) {
                        const element = this.listPrecios[index];

                        if (element.id === parseInt(data)) {
                          this.position = index;
                          this.encontrado = true;
                        }
                      }
                      if (this.encontrado) {
                        this.listPrecios.splice(this.position, 1);

                        this.position = 0;
                        this.encontrado = false;
                      } else {
                        let precios = {
                          id: parseInt(data),
                          data: res,
                        };

                        if (!this.listPrecios.length) {
                        } else {
                          this.listPrecios.push(precios);
                        }
                      }
                    },
                    (err) => {
                      console.log(err);
                    }
                  );
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }

        break;
      case "linea":
        if (this.eliminado == 0) {
          this.listPrecios = [];
          this.eliminado = 1;
        }
        let linea = this.EliminarData(data, this.listLineas);

        if (linea) {
          this.EliminarData(parseInt(dta.id), this.listPrecios);
          this.EliminarData(parseInt(data), this.familiasSeleccion);

          for (let index = 0; index < this.listPrecios.length; index++) {
            const element = this.listPrecios[index];
            if (element.id == dta.id) {
              this.position = index;
              this.encontrado = true;
            }
          }
          if (this.encontrado) {
            this.listPrecios.splice(this.position, 1);

            this.position = 0;
            this.encontrado = false;
          }
        } else {
          let consulta;
          if (
            this.formaVehiculo === 0 &&
            this.segmento === 0 &&
            this.servicio === 0
          ) {
            consulta =
              this.urlFiltros + "lineas?marca=" + dta.id + "&familia=" + data;
          } else if (
            this.formaVehiculo != 0 &&
            this.segmento === 0 &&
            this.servicio === 0
          ) {
            consulta =
              this.urlFiltros +
              "lineas?marca=" +
              dta.id +
              "&familia=" +
              data +
              "&forma=" +
              this.formaVehiculo;
          } else if (
            this.formaVehiculo === 0 &&
            this.segmento != 0 &&
            this.servicio === 0
          ) {
            consulta =
              this.urlFiltros +
              "lineas?marca=" +
              dta.id +
              "&familia=" +
              data +
              "&segmento=" +
              this.segmento;
          } else if (
            this.formaVehiculo === 0 &&
            this.segmento === 0 &&
            this.servicio != 0
          ) {
            consulta =
              this.urlFiltros +
              "lineas?marca=" +
              dta.id +
              "&familia=" +
              data +
              "&tipo_servicio=" +
              this.servicio;
          } else if (
            this.formaVehiculo != 0 &&
            this.segmento != 0 &&
            this.servicio != 0
          ) {
            consulta =
              this.urlFiltros +
              "lineas?marca=" +
              dta.id +
              "&familia=" +
              data +
              "&forma=" +
              this.formaVehiculo +
              "&segmento=" +
              this.segmento +
              "&tipo_servicio=" +
              this.servicio;
          }

          //cuando se selecciona la forma y un segmento
          else if (
            this.formaVehiculo != 0 &&
            this.segmento != 0 &&
            this.servicio === 0
          ) {
            consulta =
              this.urlFiltros +
              "lineas?marca=" +
              dta.id +
              "&familia=" +
              data +
              "&forma=" +
              this.formaVehiculo +
              "&segmento=" +
              this.segmento;
          }
          //cuando se selecciona la forma y tipo servicio
          else if (
            this.formaVehiculo != 0 &&
            this.segmento === 0 &&
            this.servicio != 0
          ) {
            consulta =
              this.urlFiltros +
              "lineas?marca=" +
              dta.id +
              "&familia=" +
              data +
              "&forma=" +
              this.formaVehiculo +
              "&tipo_servicio=" +
              this.servicio;
          }

          //segmento y tipo de servicio
          else if (
            this.formaVehiculo === 0 &&
            this.segmento != 0 &&
            this.servicio != 0
          ) {
            consulta =
              this.urlFiltros +
              "lineas?marca=" +
              dta.id +
              "&familia=" +
              data +
              "&segmento=" +
              this.segmento +
              "&tipo_servicio=" +
              this.servicio;
          }

          this._globalService.getData(consulta).subscribe(
            (res) => {
              let array = {
                id: data,
                nombre: dta.nombre,
                marca: dta.id,
                familia: data,
                data: res,
              };

              this.familiasSeleccion.push(data);
              this.listLineas.push(array);

              this._globalService
                .getData(
                  this.urlPrecios + "?marca=" + dta.id + "&familia=" + data
                )
                .subscribe(
                  (res) => {
                    let precios = {
                      id: dta.id,
                      data: res,
                    };

                    this.listPrecios.push(precios);
                  },
                  (err) => {
                    console.log(err);
                  }
                );
            },
            (err) => {
              console.log(err);
            }
          );
        }

        break;

      case "precioLinea":
        let lineaP = this.EliminarData(parseInt(data), this.listPrecios);

        if (lineaP) {
        } else {
          this._globalService
            .getData(this.urlPrecios + "?linea=" + data)
            .subscribe(
              (res) => {
                for (let index = 0; index < this.listPrecios.length; index++) {
                  const element = this.listPrecios[index];

                  if (
                    element.id === res[0].linea ||
                    element.id === res[0].marca
                  ) {
                    this.listPrecios.splice(index, 1);
                  }
                }

                let precios = {
                  id: data,
                  data: res,
                };

                this.listPrecios.push(precios);
              },
              (err) => {
                console.log(err);
              }
            );
        }

        break;

      case "caja":
        if (this.eliminado == 1) {
          this.listPrecios = [];
          this.eliminado = 2;
        }
        this.caja = data;

        this.listLineas = [];

        for (let index = 0; index < this.listFamilias.length; index++) {
          const element = this.listFamilias[index];

          for (let index = 0; index < this.familiasSeleccion.length; index++) {
            const familia = this.familiasSeleccion[index];
            this._globalService
              .getData(
                this.urlFiltros +
                  "lineas?marca=" +
                  element.id +
                  "&familia=" +
                  familia +
                  "&caja_cambios=" +
                  this.caja
              )
              .subscribe(
                (res) => {
                  let array = {
                    id: familia,
                    nombre: element.nombre,
                    data: res,
                  };

                  this.listLineas.push(array);
                },
                (err) => {
                  console.log(err);
                }
              );
          }
        }

        break;

      case "forma":
        this.formaVehiculo = parseInt(data);

        break;

      case "traccion":
        this.traccion = parseInt(data);

        if (this.eliminado == 1) {
          this.listPrecios = [];
          this.eliminado = 2;
        }
        this.caja = data;

        this.listLineas = [];

        for (let index = 0; index < this.listFamilias.length; index++) {
          const element = this.listFamilias[index];

          for (let index = 0; index < this.familiasSeleccion.length; index++) {
            const familia = this.familiasSeleccion[index];
            this._globalService
              .getData(
                this.urlFiltros +
                  "lineas?marca=" +
                  dta.id +
                  "&familia=" +
                  data +
                  "linea_traccion" +
                  this.traccion
              )
              .subscribe(
                (res) => {
                  let array = {
                    id: familia,
                    nombre: element.nombre,
                    data: res,
                  };

                  this.listLineas.push(array);
                },
                (err) => {
                  console.log(err);
                }
              );
          }
        }

        break;
      case "carroceria":
        this.carroceria = data;

        break;

      case "segmento":
        this.segmento = data;

        break;

      case "servicio":
        this.servicio = data;

        break;

      case "motor":
        this.motor = data;

        if (this.eliminado == 1) {
          this.listPrecios = [];
          this.eliminado = 2;
        }
        this.caja = data;

        this.listLineas = [];

        for (let index = 0; index < this.listFamilias.length; index++) {
          const element = this.listFamilias[index];

          for (let index = 0; index < this.familiasSeleccion.length; index++) {
            const familia = this.familiasSeleccion[index];
            this._globalService
              .getData(
                this.urlFiltros +
                  "lineas?marca=" +
                  element.id +
                  "&familia=" +
                  familia +
                  "&tipo_motor=" +
                  this.motor
              )
              .subscribe(
                (res) => {
                  let array = {
                    id: familia,
                    nombre: element.nombre,
                    data: res,
                  };

                  this.listLineas.push(array);
                },
                (err) => {
                  console.log(err);
                }
              );
          }
        }
        break;

      case "equipamiento":
        this.equipamiento = data;

        if (this.eliminado == 1) {
          this.listPrecios = [];
          this.eliminado = 2;
        }
        this.caja = data;

        this.listLineas = [];

        for (let index = 0; index < this.listFamilias.length; index++) {
          const element = this.listFamilias[index];

          for (let index = 0; index < this.familiasSeleccion.length; index++) {
            const familia = this.familiasSeleccion[index];
            this._globalService
              .getData(
                this.urlFiltros +
                  "lineas?marca=" +
                  element.id +
                  "&familia=" +
                  familia +
                  "&nivel_equipamiento=" +
                  this.equipamiento
              )
              .subscribe(
                (res) => {
                  let array = {
                    id: familia,
                    nombre: element.nombre,
                    data: res,
                  };

                  this.listLineas.push(array);
                },
                (err) => {
                  console.log(err);
                }
              );
          }
        }
        break;
    }
  }

  SelectTraccion(event) {
    let data = parseInt(event.value);
    console.log(data);
  }
  /**
    Metodo para eliminar algun dato por ID
   */

  EliminarData(id, arreglo) {
    for (let index = 0; index < arreglo.length; index++) {
      const element = arreglo[index];
      if (element.id == id) {
        this.position = index;
        this.encontrado = true;
      }
    }
    if (this.encontrado) {
      arreglo.splice(this.position, 1);

      this.position = 0;
      this.encontrado = false;
      return true;
    } else {
      return false;
    }
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // ───────────────────────────Metodos para obtener los precios
  // ────────────────────────────────────────────────────────────────────────────────

  obtenerPrecio() {
    this._globalService.getData(this.urlPrecios).subscribe(
      (res) => {
        let usado = {
          linea: 0,
          data: res[0].lista_usado,
        };
        let nuevo = {
          linea: 0,
          data: res[0].lista_nuevo,
        };

        let precios = {
          id: 199703,
          data: res,
        };

        this.listPrecios.push(precios);
        this.listAnoNuevo = res[0].lista_nuevo;
        this.listAnoUsados = res[0].lista_usado;

        this.cantAnosUsuados = this.listAnoUsados.length;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // ────────────────────────────────────────────────────────────────────────────────
  // ───────────────────────────Metodos para obtener los precios cpn los filtros
  // ────────────────────────────────────────────────────────────────────────────────

  obtenerFiltro() {
    let urlFinal;

    this._globalService.getData(this.urlPrecios).subscribe(
      (res) => {
        console.log(res);
        this.listPrecios = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
