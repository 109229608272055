import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politicas-publicacion',
  templateUrl: './politicas-publicacion.component.html',
  styleUrls: ['./politicas-publicacion.component.css']
})
export class PoliticasPublicacionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0);
  }

}
